import { get, post, put } from '@/utils/request'

export default {
  getCode(params = {}) {
    return get('/common/phone/exist', params)
  },
  login(data) {
    return post(`/rest/customer/login`, data)
  },
  register(data) {
    return post(`/rest/customer/register`, data)
  },
  info() {
    return get(`/rest/customer/info`)
  },
  resetEmail(email) {
    return get(`/rest/customer/reset`, { email })
  },
  resetPassword(token, body) {
    return put(`/rest/customer/reset/${token}`, body)
  },
  exists(email) {
    return post(`/rest/customer/exists`, { email })
  },
  update(body) {
    return put(`/rest/customer/own`, body)
  }
}
