import Vue from 'vue'
import Vuex from 'vuex'
import userApi from '@/api/user'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('x-auth-token') || '',
    user: {},
    locale: localStorage.getItem('locale') || 'zh-CN'
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOCALE(state, lang) {
      state.locale = lang
    }
  },
  actions: {
    async getUser({ commit }) {
      const user = await userApi.info()
      commit('SET_USER', user)
    },
    updateLocale({ commit }, lang) {
      localStorage.setItem('locale', lang)
      Locale.use(lang, lang === 'en-US' ? enUS : zhCN)
      commit('SET_LOCALE', lang)
    }
  },
  modules: {
  }
})
