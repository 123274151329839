import Vue from 'vue'
import { Toast, Button, Loading, Tabs, Tab, NavBar, Tag, Checkbox, ActionSheet, Cell, Empty, Dialog, Tabbar, TabbarItem, Field, Popup, Picker, RadioGroup, Radio } from 'vant'
import VueI18n from 'vue-i18n'

import App from './App.vue'
import router from './router'
import store from './store'
import messages from '@/utils/messages'

// 引入svg图标
import '@/icons'

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Loading)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(NavBar)
Vue.use(Tag)
Vue.use(Checkbox)
Vue.use(ActionSheet)
Vue.use(Cell)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)

const i18n = new VueI18n({
  locale: 'zh-CN',
  messages
})

router.beforeEach(async ({ meta }, from, next) => {
  if (meta.auth) {
    const { user, token } = store.state
    // 直接没有登录
    if (!token) {
      return next('/login')
    }
    // 未获取用户信息
    if (!user.uuid) {
      await store.dispatch('getUser')
    }
  }
  return next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
