import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Order',
    meta: { auth: true },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Email',
    component: () => import('../views/Email.vue')
  },
  {
    path: '/register-final',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/user/forgot.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/user/reset.vue')
  },
  {
    path: '/user',
    name: 'User',
    meta: { auth: true },
    component: () => import('../views/User.vue')
  },
  {
    path: '/apply',
    name: 'Apply',
    meta: { auth: true },
    component: () => import('../views/Apply.vue')
  },
  {
    path: '/appointment',
    name: 'Appointment',
    meta: { auth: true },
    component: () => import('../views/appointment/form.vue')
  },
  {
    path: '/appointment-list',
    name: 'AppointmentList',
    meta: { auth: true },
    component: () => import('../views/appointment/list.vue')
  },
  {
    path: '/chat/:room_id',
    name: 'Chat',
    meta: { auth: true },
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/meeting/:room_id',
    name: 'Metting',
    meta: { auth: true },
    component: () => import('../views/Meeting.vue')
  },
  {
    path: '/order/:uuid',
    name: 'ApplyDetail',
    meta: { auth: true },
    component: () => import('../views/ApplyDetail.vue')
  },
  {
    path: '/appointment/:uuid',
    name: 'AppointmentDetail',
    meta: { auth: true },
    component: () => import('../views/appointment/detail.vue')
  },
  {
    path: '/pay-klarna/:uuid/:type',
    name: 'PayKlarna',
    meta: { auth: true },
    component: () => import('../views/payment/klarna.vue')
  },
  {
    path: '/pay-klarna-retrieve/:order_id',
    name: 'PayKlarnaRetrieve',
    meta: { auth: true },
    component: () => import('../views/payment/retrieve.vue')
  },
  {
    path: '/pickup/:itemId/:type',
    name: 'Pickup',
    component: () => import('../views/pickup/pickup.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/403.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
