import axios from 'axios'
import { Toast } from 'vant'

// 自己用的后端server
const http = axios.create({
  baseURL: '',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('x-auth-token') || ''
    if (token) {
      config.headers['Authentication'] = `${token}`
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
http.interceptors.response.use(
  response => {
    const res = response.data

    if (res.status == 500) {
      (new Image).src = `https://log.dingmatrix.com?url=${encodeURIComponent(response.config.url)}&error=${encodeURIComponent((res.data || res.msg || res.message || '').substr(0, 100))}`
    }

    if (res.status == 303) {
      window.location.href = '/login?path=' + location.pathname
      return false
    }

    if (res.status != '200' && res.status != 0 && res.code !== 200) {
      Toast(res.data || res.msg || res.message)
			return Promise.reject(res.data || res.msg || res.message)
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default http

export function get(url, params = {}) {
  return http.get(url, {
    params
  })
}

export function post(url, data = {}) {
  return http.post(url, data)
}

export function put(url, data = {}) {
  return http.put(url, data)
}

export function upload(url, formData) {
  return http.post(url, formData, {
    'Content-Type': 'multipart/form-data'
  })
}
