<template>
  <div id="app">
    <router-view/>
    <van-tabbar route v-if="showTabBar">
      <van-tabbar-item replace to="/" icon="home-o">{{$t('home')}}</van-tabbar-item>
      <van-tabbar-item replace to="/appointment-list" icon="tv-o">{{$t('appoint')}}</van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="setting-o">{{$t('mine')}}</van-tabbar-item>
    </van-tabbar>
    <a class="app-kefu" v-if="showKefu" href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97877707c026159be619e7b83425d6535ac972226fb08c31d3e5c7b48bbfaa8934e3949a352c1fd05bbcee3f0cef96aea7" target="_blank">
      <svg-icon icon-class="message" />
      <p v-if="false">在线客服</p>
    </a>
  </div>
</template>

<script type="text/javascript">
import { Locale } from 'vant'
// import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
export default {
  data() {
    return {
      showTabBar: false,
      showKefu: false,
    }
  },
  watch: {
    '$route': function () {
      if (this.$route.path === '/' || this.$route.path === '/user' || this.$route.path === '/appointment-list') {
        this.showTabBar = true
      }
      else {
        this.showTabBar = false
      }
      this.showKefu = this.$route.meta && this.$route.meta.auth
    }
  },
  created() {
    this.$i18n.locale = this.$store.state.locale
    // if (this.$i18n.locale === 'en-US') {
    //   Locale.use('en-US', enUS)
    // }
    // else {
      Locale.use('zh-CN', zhCN)
    // }
  }
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.c-gray {
  color: #666;
}
.c-orange {
  color: @orange;
}
.c-red {
  color: @red;
}
.ml {
  &m {
    margin-left: 12px;
  }
}
.main-container {
  padding-bottom: 50px;
}
.apply {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    padding-bottom: 94px;
  }
  &-alert {
    padding: 15px;
    &-content {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
  }
  &-gap {
    background: #F0F4F6;
    height: 8px;
  }
  &-content {
    flex: 1;
    overflow-y: auto;
  }
  &-section {
    background: #fff;
  }
  &-item {
    padding: 10px 15px 15px;
    color: #333;
    font-size: 16px;
    position: relative;
    .van-loading {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
    &.dipm-item {
      padding-bottom: 6px;
      label {
        margin-bottom: 0px;
      }
    }
    &-hd {
      display: flex;
      align-items: center;
      label {
        flex: 1;
      }
    }
    &-ipt {
      display: flex;
      input {
        flex: 1;
      }
    }
    .van-cell {
      padding: 6px 0;
      font-size: 16px;
      margin-top: 6px;
    }
    label {
      line-height: 24px;
      display: block;
      margin-bottom: 12px;
      position: relative;
      &[required]:before {
        position: absolute;
        content: '*';
        color: #F56C6C;
        font-size: 14px;
        left: -7px;
        margin-top: 2px;
      }
    }
    input {
      outline: none;
      border: 0 none;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  &-urgent {
    float: right;
  }
  &-add {
    color: @orange;
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    line-height: 24px;
    .svg-icon {
      margin-right: 2px;
    }
  }
  &-fee {
    font-size: 14px;
    color: #F56C6C;
    padding: 10px 15px 16px;
    background: #F0F4F6;
    .express-fee {
      color: #555;
      margin-left: 24px;
    }
  }
  &-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;
    background: #fff;
    z-index: 10;
  }
}
// 层动画
.layer-enter-active, .layer-leave-active {
  opacity: 1;
  transition: all .3s;
}
.layer-enter, .layer-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.app-kefu {
  position: fixed;
  right: 16px;
  bottom: 80px;
  font-size: 24px;
  background-color: rgb(24, 128, 255);
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 14px;
  padding: 10px;
  color: #fff;
  text-align: center;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  p {
    margin: 0;
    font-size: 12px;
  }
}
</style>
