const messages = {
  'zh-CN': {
    login: {
      title: '请登录',
      subTitle: '新用户？',
      signup: '创建账号',
      email: '请输入邮箱账户',
      password: '请输入密码',
      loginBtn: '登录',
      loading: '登录中...',
      forgot: '忘记密码？'
    },
    reg: {
      title: '注册账户',
      subTitle: '已有账户？',
      login: '去登录',
      email: '电子邮箱',
      button: '注册',
      success: '邮件发送成功',
      loading: '处理中...',
      backLogin: '我已注册，去登录',
      successTip: '带有激活链接的信息已经发送到你的邮箱。请打开此链接来注册你的帐号',
    },
    forgot: {
      title: '忘记密码',
      email: '注册邮箱',
      emailPlaceholder: '请填写你注册的邮箱地址',
      button: '找回密码',
      successTip: '带有激活链接的信息已经发送到你的邮箱。请打开此链接来重置你的帐号',
      backLogin: '我已重置，去登录',
      newPassword: '新密码',
      resetTitle: '重置密码',
      resetSuccess: '重置成功',
    },
    regf: {
      success: '新账户注册成功',
      title: '完善账户',
      nickname: '姓名',
      nicknameTip: '请输入姓名',
      password: '登录密码',
      passwordTip: '请输入登录密码',
      password2: '重复登录密码',
      password2Tip: '请再次输入登录密码'
    },
    order: {
      title: '我的申请单',
      add: '发起',
      all: '全部',
      total: '合计',
      waitForPick: '待揽件',
      pickDone: '已揽件',
      done: '已完成',
      cancel: '已取消',
      detail: '申请单详情',
      applyDate: '申请时间',
      editApply: '修改申请单',
      cancelApply: '取消申请单',
      review: '待审核',
      process: '处理中',
      delivery: '派送中',
      reject_reason: '拒绝原因',
      delivery_fee_tip: '请支付派送费',
      dipm_fee_tip: '请支付使馆费',
    },
    form: {
      req_user: '申请人（中文姓名，必须与实际申请人一致）',
      req_user_tip: '申请人',
      conn_user: '收/发件人(中文名请备注汉语拼音，例：快递 KuaiDi)',
      conn_email: '联系邮箱',
      conn_phone: '联系电话',
      to_address: '联系地址 (填写地址后，请从下拉菜单中选择正确的地址)',
      postal_code: '邮编',
      city: '城市',
      type: '使馆项目',
      title: '提交申请单',
      company: '公司（选填）',
      lgh: 'LGH（选填）',
      addType: '添加使馆项目',
      urgent: '加急',
      cancel: '取消',
      selectTip: '请选择',
      errTip: '信息不完整，请检查~',
      connUserTip: '申请人姓名必须为中文',
      connEmailTip: '邮箱格式不正确',
      connPhoneTip: '联系电话格式不正确（07xxxxxxxx）',
      postalCodeTip: '请输入正确的5位邮编',
      successTip: '提交成功~',
      typeTip: '使馆项目至少选填一个',
      selectDipm: '选择使馆',
      selectDipmTip: '请选择使馆',
      attach: '上传附件（图片，非必填）',
      uploading: '上传中',
      uploadFailed: '上传失败',
      pickUpInfo: '揽件信息',
      deliveryInfo: '派送信息',
      trackingNo: '物流单号',
      lghTip: 'LGH只能是数字',
      deliveryType: '派送类型',
      deliveryTypeTip: '请选择派送类型',
    },
    appointment: {
      title: '提交视频认证预约',
      subject: '预约主题',
      time: '预约时间段',
      listTitle: '我的视频认证预约',
      emptyTip: '无可预约时间段',
    },
    status: {
      10: '待审核',
      20: '待付快递费',
      30: '揽件中',
      40: '处理中',
      50: '待付使馆费',
      60: '派送中',
      70: '已完成',
      99: '已取消'
    },
    reviewStatus: {
      3: '审核失败'
    },
    locale: 'English',
    embassy: '使领馆信息化服务平台',
    mine: '我的',
    nickname: '姓名',
    email: '电子邮箱',
    logout: '退出登录',
    home: '申请',
    appoint: '预约',
    lang: '语言',
    back: '返回',
    remark: '备注',
    embassyFee: '使馆费',
    expressFee: '快递费',
    urgentFee: '加急费',
    submit: '提交',
    submiting: '提交中...',
    empty: '申请单为空',
    loading: '加载中...',
    required: '不能为空',
    user_manual: '使领馆申请单提交流程',
  },
  'en-US': {
    locale: '中文',
    login: {
      title: 'Please Login',
      subTitle: 'New User? ',
      signup: 'Sign Up',
      email: 'Email',
      password: 'Password',
      loginBtn: 'Log In',
      loading: 'Login...',
      forgot: 'Forgot Password?'
    },
    reg: {
      title: 'Sign Up',
      subTitle: 'Has Account? ',
      login: 'Log In',
      email: 'Email',
      button: 'Sign Up',
      success: 'Send Success',
      loading: 'Processing...',
      backLogin: 'I have registered, go to login',
      successTip: 'The information with the active link has been sent to your mailbox. Please open this link to register your account.'
    },
    forgot: {
      title: 'Forgot Password',
      email: 'Registered Email',
      emailPlaceholder: 'Please input your registered email.',
      button: 'Retrieve Password',
      successTip: 'The information with the active link has been sent to your mailbox. Please open this link to reset your account.',
      backLogin: 'I have reset, go to login',
      newPassword: 'New Password',
      resetTitle: 'Reset Password',
      resetSuccess: 'Reset Success',
    },
    regf: {
      success: 'Sign Up Success',
      title: 'Finish Account',
      nickname: 'Nickname',
      nicknameTip: 'Nickname',
      password: 'Password',
      passwordTip: 'Password',
      password2: 'Repeat Passowrd',
      password2Tip: 'Repeat Password'
    },
    order: {
      title: 'My application',
      add: 'New',
      all: 'All',
      total: 'Total',
      waitForPick: 'Waiting for pick up',
      pickDone: 'Pick up done',
      done: 'Done',
      cancel: 'Cancelled',
      detail: 'Application',
      applyDate: 'Application date',
      editApply: 'Edit application',
      cancelApply: 'Cancel application',
      review: 'Pending review',
      process: 'Processing',
      delivery: 'Delivering',
      reject_reason: 'Reject reason',
      delivery_fee_tip: 'Please pay the delivery fee',
      dipm_fee_tip: 'Please pay the embassy fee',
    },
    form: {
      req_user: '申请人（中文姓名，必须与实际申请人一致）',
      conn_user: 'To / From',
      conn_email: 'Email',
      conn_phone: 'Phone Number',
      to_address: 'Address',
      postal_code: 'Postal code',
      city: 'City',
      type: 'Type of Legalization',
      title: 'Submit an application',
      company: 'Company(optional)',
      lgh: 'LGH(optional)',
      addType: 'Add more Legalization',
      urgent: 'Urgent',
      cancel: 'Cancel',
      selectTip: 'Please select',
      errTip: 'Incomplete information, please check~',
      connUserTip: 'The name of the applicant must be Chinese',
      connEmailTip: 'Wrong email format',
      connPhoneTip: 'Wrong phone format (07xxxxxxxx)',
      postalCodeTip: 'Wrong postal code format, only 5 numbers',
      successTip: 'Submitted successfully',
      typeTip: 'At laast one of legalization',
      selectDipm: 'Select Embassy',
      selectDipmTip: 'Please select Embassy',
      attach: 'Attachments (Images, Optional)',
      uploading: 'Uploading',
      uploadFailed: 'Upload Error',
      pickUpInfo: 'Pick up information',
      deliveryInfo: 'Delivery Information',
      trackingNo: 'Tracking No',
      lghTip: 'LGH only support numbers',
      deliveryType: 'Delivery Type',
      deliveryTypeTip: 'Please select delivery type',
    },
    appointment: {
      title: 'Submit video authentication appointment',
      subject: 'Appointment topic',
      time: 'Appointment period',
      listTitle: 'My video authentication appointment',
      emptyTip: 'No available appointment period',
    },
    embassy: 'EMBASSY OF THE PEOPLE\'S REPUBLIC OF CHINA IN THE KINGDOM OF SWEDEN',
    mine: 'User Center',
    nickname: 'Nickname',
    email: 'Email',
    logout: 'Log Out',
    home: 'Application',
    appoint: 'Appointment',
    lang: 'Language',
    back: 'Back',
    remark: 'Remark',
    embassyFee: 'Embassy fee',
    expressFee: 'Delivery fee',
    urgentFee: 'Express',
    submit: 'Submit',
    submiting: 'loading...',
    empty: 'Empty',
    loading: 'loading...',
    required: ' cannot be empty',
    user_manual: 'user operation manual',
    status: {
      10: 'Pending review',
      20: '',
      30: 'Picking',
      40: 'Processing',
      50: '待付使馆费',
      60: 'Delivering',
      70: 'Done',
      99: 'Cancelled'
    },
    reviewStatus: {
      3: '审核失败'
    },
  }
}

export default messages
